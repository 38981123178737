export const loki =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Loki%2Flogo.png?alt=media&token=498a57e5-4cd8-4af6-b17a-8672d49ba2bf)";
export const fluff =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Fluff%2Flogo.png?alt=media&token=0a121b95-a0ad-4d0c-b223-6170f0281cc9)";
export const h36 =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/H36%2Flogo.png?alt=media&token=f0f357fd-f794-4157-8c31-da8731bbae37)";
export const sandraMartins =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/SandraMartins%2Fminiature-min.png?alt=media&token=16baaef1-ca84-4898-b3fd-f54ac9dc0d32)";
export const moyotia =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Moyotia%2Fminiature-min.png?alt=media&token=ebe5446c-8dc1-4834-927d-c47aad1e19d0)";
export const mizu =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Mizu%2Fminiature-min.png?alt=media&token=890bb3a5-dc73-4579-b2ea-4e56270732b8)";
export const jo2024 =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/JO2024%2FLogo%2FLogo-min.png?alt=media&token=f0617798-1b67-414f-a004-071f0020d1f6)";
export const rain =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Rain%2Fminiature-min%203.png?alt=media&token=c881442a-373b-4517-b5cf-8c5bf351d3c8)";
export const uniqlo =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Uniqlo%2Fminiature-min.png?alt=media&token=fa4b34d0-7e05-4423-8338-8f698a4e98a4)";
export const pong =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Pong%2Fminiature-min%202.png?alt=media&token=420b242d-5990-4c88-9868-45f66e1f49a0)";
export const blackValley =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/BlackValley%2Fminiature-min-1.png?alt=media&token=4c63c522-8b7f-4d54-b89d-de308693692b)";
export const nyne =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Nyne%2Fminiature-min.png?alt=media&token=5340f4ad-1d33-44ba-9da2-f0b05d959cc6)";
export const lodan =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Lodan%2Fminiature-min.png?alt=media&token=1cb561f7-9274-4a8d-80ad-9350f3095f68)";
export const coquelicot =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Coquelicot%2Fminiature_2-min.png?alt=media&token=f17c680a-defd-4381-9769-8b3097a4e0ea)";
export const angersNantesOpera =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/AngersNantesOpera%2Fminiature-min.png?alt=media&token=340d883e-adc3-4055-85c2-02ae783d278f)";
export const jump =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Jump%2Fminiature-min.png?alt=media&token=c49df849-e0a1-4665-8380-e78842b61919)";
export const identiteVisuelle =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/IdentiteVisuelle%2Fminiature-min.png?alt=media&token=25a68425-370c-41a1-bfac-c830d3e70cc3)";
  export const dance =
  "url(https://firebasestorage.googleapis.com/v0/b/frb-moijesuisben-portfolio.appspot.com/o/Dance%2Fdance.png?alt=media&token=d0d5ede0-77f7-468e-9428-0fb6771e8739)";

import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import MenuHeader from '../components/Menu/MenuHeader';
import * as variables from '../variables';
import parcours from '../data/parcours.json';
import works from '../data/work.json';
import SkillsModal from '../components/Modal/SkillsModal';
import description from '../data/description.json';

const useStyles = createUseStyles({
  blockAbout: {
    marginTop: variables.space1,
    padding: '0 50px',
  },
  scroll: {
    height: '100vh',
    overflow: 'auto',
  },
  title: {
    textTransform: 'uppercase',
    fontSize: variables.fs_xl,
    fontWeight: variables.fw_black,
    margin: 0,
  },
  presentation: {
    fontSize: variables.fs_xs,
    paddingRight: 'calc(0px + (500 - 0) * ((100vw - 320px) / (1920 - 320)))',
    margin: 0,
    marginTop: variables.space1,
    marginBottom: variables.space1,
    lineHeight: '1.5em',
  },
  columnsDesktop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  columnsMobile: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  column: {
    width: '100%',
    flex: '0 50%',
    display: 'flex',
    flexDirection: 'column',
  },
  titleColumn: {
    textTransform: 'uppercase',
    fontWeight: variables.fw_bold,
    fontSize: variables.fs_sm,
  },
  parcours: {
    marginBottom: '1em',
    '& > *': {
      fontSize: variables.fs_xs,
      margin: 0,
      marginBottom: '0.3em',
    },
  },
  textMedium: {
    fontWeight: variables.fw_medium,
  },
  textLight: {
    fontWeight: variables.fw_light,
  },
  works: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    '& > *': {
      width: '100%',
      marginBottom: '1em',
      flex: '0 50%',
      fontSize: variables.fs_xs,
    },
    '& > * > *': {
      margin: 0,
      marginBottom: '0.3em',
    },
  },
  worksSmall: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginBottom: '1em',
      fontSize: variables.fs_xs,
    },
    '& > * > *': {
      margin: 0,
      marginBottom: '0.3em',
    },
  },
});

export default function Projects() {
  const classes = useStyles();
  const width = window.innerWidth;

  return (
    <div className={classes.scroll}>
      <MenuHeader />
      <div className={classes.blockAbout}>
        <h1 className={classes.title}>{description.title}</h1>
        <p className={classes.presentation}>{description.text}</p>
        <div className={clsx(classes.columnsDesktop, width < 992 && classes.columnsMobile)}>
          <div className={classes.column}>
            <h2 className={classes.titleColumn}>Studies</h2>
            {parcours.map((parc, index) => (
              <div key={index} className={classes.parcours}>
                <p className={classes.textMedium}>{parc.year}</p>
                <p className={classes.textLight}>{parc.school}</p>
                <p className={classes.textMedium}>{parc.degree}</p>
              </div>
            ))}
          </div>
          <div className={classes.column}>
            <h2 className={classes.titleColumn}>Last Work</h2>
            <div className={clsx(classes.works, width < 992 && classes.worksSmall, width < 768 && classes.works)}>
              {works.map((work, index) => (
                <div key={index}>
                  <p className={classes.textLight}>{work.date}</p>
                  <p className={classes.textMedium}>{work.company}</p>
                  <p className={classes.textMedium}>{work.job}</p>
                </div>
              ))}
            </div>
            <h2 className={classes.titleColumn}>Skills</h2>
            <SkillsModal />
          </div>
        </div>
      </div>
    </div>
  );
}

import React from "react";
import { createUseStyles } from "react-jss";
import * as variables from "../../variables";
import Close from "../Close/Close";

const useStyles = createUseStyles({
  projectOpen: {
    overflow: "auto",
    zIndex: 2,
    position: "absolute",
    top: 0,
    left: -10,
    height: "100vh",
    width: "100vw",
    backgroundColor: variables.akaroa,
    padding: "5% 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  name: {
    textAlign: "center",
    fontSize: variables.fs_xl,
    fontWeight: variables.fw_black,
    marginTop: variables.space2,
    margin: 0,
  },
  info: {
    marginBottom: variables.space1,
    fontWeight: variables.fw_medium,
  },
  brief: {
    textAlign: "center",
    fontSize: variables.fs_sm,
    fontWeight: variables.fw_bold,
    fontStyle: "italic",
    maxWidth: "60vw",
    margin: 0,
  },
  solution: {
    textAlign: "center",
    fontSize: variables.fs_xs,
    fontWeight: variables.fw_medium,
    maxWidth: "60vw",
    marginBottom: variables.space1,
  },
  images: {
    /* display: "flex",
    flexDirection: "column",
    justifyContent: "center", */
    textAlign: "center",
    marginBottom: variables.space2,
    // maxHeight: "calc(120px + (500 - 120) * ((100vw - 320px) / (1920 - 320)))",
  },
  image: {
    marginBottom: variables.space1,
    width: "calc(200px + (1000 - 200) * ((100vw - 320px) / (1920 - 320)))",
    // maxHeight: "calc(120px + (500 - 120) * ((100vw - 320px) / (1920 - 320)))",
  },
});

export default function ProjectOpen({
  // eslint-disable-next-line react/prop-types
  name,
  year,
  category,
  projectType,
  brief,
  solution,
  tools,
  onClick,
  media,
  dataMedia,
}) {
  const classes = useStyles();

  return (
    <div className={classes.projectOpen}>
      <Close onClick={onClick} />
      <h1 className={classes.name}>{name}</h1>
      <h2 className={classes.info}>
        {year} | {projectType} | with {tools}
      </h2>
      <h3 className={classes.brief}>{brief}</h3>
      <h4 className={classes.solution}>{solution}</h4>
      <div className={classes.images}>
        {dataMedia.map((data, index) => (
          <img className={classes.image} src={data.media} alt={data.media} />
        ))}
      </div>
    </div>
  );
}

import React from "react";
import { createUseStyles } from "react-jss";
import { layoutGenerator } from "react-break";
import clsx from "clsx";
import * as variables from "../../variables";
import Close from "../Close/Close";

const useStyles = createUseStyles({
  container: {
    // border: '1px solid blue',
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    marginTop: variables.space1,
    marginBottom: variables.space1,
  },
  menuDesktop: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    width: "100%",
    // border: '1px solid red',
  },
  menuItem: {
    listStyle: "none",
    // border: '1px solid green',
    fontWeight: variables.fw_bold,
    textTransform: "uppercase",
    "& > *": {
      textDecoration: "none",
    },
    "& > *:hover": {
      "-webkit-text-stroke": "1px #38383a",
      color: "transparent",
    },
  },
  menuItemDesktop: {
    fontSize: variables.fs_sm,
  },
  menuItemMobile: {
    fontSize: variables.fs_lg,
  },
  openMenuMobile: {
    display: "flex",
    justifyContent: "center",
    fontWeight: variables.fw_bold,
    fontSize: variables.fs_sm,
    textTransform: "uppercase",
    "&:hover": {
      "-webkit-text-stroke": "1px #38383a",
      color: "transparent",
    },
  },
  containerMobileFullScreen: {
    height: "100vh",
    width: "100%",
    position: "absolute",
    bottom: 0,
    padding: "5% 0",
    backgroundColor: variables.akaroa,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
    zIndex: 2,
  },
  menuMobilePosition: {
    height: "90%",
    display: "flex",
    alignItems: "center",
  },
  menuMobile: {
    padding: 0,
    listStyle: "none",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-evenly",
    height: "50%",
  },
});

export default function MenuProject({
  // eslint-disable-next-line react/prop-types
  onClickAD,
  onClickCC,
  onClickWD,
  handleOpenMenu,
  handleCloseMenu,
  open,
}) {
  const classes = useStyles();

  const layout = layoutGenerator({
    mobile: 0,
    desktop: 992,
  });
  
  const OnMobile = layout.is("mobile");
  const OnDesktop = layout.is("desktop");

  return (
    <>
      <OnDesktop>
        <div className={classes.container}>
          <ul className={classes.menuDesktop}>
            <li className={clsx(classes.menuItem, classes.menuItemDesktop)}>
              <p onClick={onClickAD}>Art Direction</p>
            </li>
            <li className={clsx(classes.menuItem, classes.menuItemDesktop)}>
              <p onClick={onClickCC}>Creative Coding</p>
            </li>
            <li className={clsx(classes.menuItem, classes.menuItemDesktop)}>
              <p onClick={onClickWD}>Web Development</p>
            </li>
          </ul>
        </div>
      </OnDesktop>
      <OnMobile>
        {open ? (
          <div className={classes.containerMobileFullScreen}>
            <Close onClick={handleCloseMenu} absolute />
            <div className={classes.menuMobilePosition}>
              <ul className={classes.menuMobile}>
                <li className={clsx(classes.menuItem, classes.menuItemMobile)}>
                  <p onClick={onClickAD}>Art Direction</p>
                </li>
                <li className={clsx(classes.menuItem, classes.menuItemMobile)}>
                  <p onClick={onClickCC}>Creative Coding</p>
                </li>
                <li className={clsx(classes.menuItem, classes.menuItemMobile)}>
                  <p onClick={onClickWD}>Web Development</p>
                </li>
              </ul>
            </div>
          </div>
        ) : (
          <p className={classes.openMenuMobile} onClick={handleOpenMenu}>
            Filters
          </p>
        )}
      </OnMobile>
    </>
  );
}

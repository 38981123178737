import React from 'react';
import { createUseStyles } from 'react-jss';
import {
  BrowserRouter as Router, Switch, Route, Link,
} from 'react-router-dom';
import * as variables from '../variables';
import Logo from '../components/Logo/Logo';

const useStyles = createUseStyles({
  preLanding_block: {
    backgroundColor: variables.akaroa,
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  preLanding_elements: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  preLanding_title: {
    margin: 0,
    '-webkit-text-stroke': '1px #38383a',
    color: 'transparent',
    fontWeight: variables.fw_black,
    fontSize: variables.fs_lg,
    textTransform: 'uppercase',
    textAlign: 'center',
    textDecoration: 'none',
    letterSpacing: '0.1vw',
    '&:hover': {
      '-webkit-text-stroke': '0',
      background: 'url(media/image.jpg)',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundClip: 'text',
      '-webkit-background-clip': 'text',
      color: 'transparent',
    },
  },
  preLanding_button: {
    width: '50vw',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
  },
  preLanding_text: {
    position: 'relative',
    backgroundColor: variables.akaroa,
    border: 'none',
    textTransform: 'uppercase',
    textDecoration: 'none',
    textAlign: 'center',
    fontSize: variables.fs_xs,
    fontWeight: variables.fw_medium,
    marginTop: variables.space1,
    '&:after': {
      // width: "100%",
      content: 'close-quote',
      position: 'absolute',
      left: '0',
      bottom: '-5px',
      height: '1.5px',
      backgroundColor: variables.tuna,
      width: 0,
      transition: 'width 0.4s',
    },
    '&:hover:after': {
      width: '100%',
    },
  },
});

export default function PreLanding() {
  const classes = useStyles();

  return (
    <div className={classes.preLanding_block}>
      <div className={classes.preLanding_elements}>
        <h1 className={classes.preLanding_title}>
          For a better experience
          {' '}
          <br />
          check on desktop
        </h1>
        <div className={classes.preLanding_button}>
          {/* <Link to="/landing" className={classes.preLanding_text}> */}
          <Link to="/home" className={classes.preLanding_text}>
            go to the website
          </Link>
          <Logo />
        </div>
      </div>
    </div>
  );
}

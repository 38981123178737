/* FONT SIZE */
export const fs_xs = 'calc(12px + (18 - 12) * ((100vw - 320px) / (1920 - 320)))';
export const fs_sm = 'calc(15px + (30 - 15) * ((100vw - 320px) / (1920 - 320)))';
export const fs_md = 'calc(20px + (40 - 20) * ((100vw - 320px) / (1920 - 320)))';
export const fs_lg = 'calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320)))';
export const fs_xl = 'calc(40px + (80 - 40) * ((100vw - 320px) / (1920 - 320)))';

/* FONT WEIGHT */
export const fw_thin = 100;
export const fw_light = 300;
export const fw_medium = 500;
export const fw_bold = 700;
export const fw_black = 900;

/* COLOR */
// export const deepFire = '#020400';
// export const toryBlue = '#0B5AA2';
export const pumice = '#CBCCCB';
// export const heavyMetal = '#393A38';
// export const white = '#f8f9fa';
export const akaroa = '#d1c39e';
export const tuna = '#38383a';

/* SPACING */

export const space1 = 'calc(25px + (50 - 25) * ((100vw - 320px) / (1920 - 320)))';
export const space2 = 'calc(50px + (100 - 50) * ((100vw - 320px) / (1920 - 320)))';
export const space3 = 'calc(75px + (150 - 75) * ((100vw - 320px) / (1920 - 320)))';

/*  */

import React from "react";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import * as variables from "../../variables";
import skillsData from "../../data/skills.json";
import Close from "../Close/Close";

const useStyles = createUseStyles({
  expandMore: {
    position: "relative",
    margin: 0,
    marginBottom: variables.space1,
    fontSize: variables.fs_xs,
    fontWeight: variables.fw_medium,
    width: "fit-content",
    "&:after": {
      bottom: "-5px",
      height: "2px",
      content: "close-quote",
      position: "absolute",
      left: "0",
      backgroundColor: variables.tuna,
      width: 0,
      transition: "width 0.4s",
    },
    "&:hover:after": {
      width: "100%",
    },
  },
  expandMoreMobile: {
    marginBottom: variables.space3,
  },
  container: {
    padding: "0 50px",
  },
  containerSmall: {
    padding: 0,
    margin: "0 0 0 25px",
  },
  skillsModal: {
    zIndex: 10,
    position: "absolute",
    overflow: "auto",
    height: "100vh",
    width: "100vw",
    // padding: "0 50px",
    top: 0,
    left: 0,
    backgroundColor: variables.akaroa,
  },
  // skillBlocks: {
  //   display: 'flex',
  //   flexDirection: 'row',
  // },
  skillBlock: {
    // border: '1px solid green',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
    "&:last-child": {
      paddingBottom: 25,
    },
  },
  skillBlockSmall: {
    flexDirection: "column",
  },
  skillItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flex: "0 30%",
    "& > div": {
      display: "flex",
      flexDirection: "row",
      width: "100%",
    },
    "& > div > *:nth-child(1)": {
      marginRight: "1em",
    },
  },
  closeBlock: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    padding: "5% 0 0 0",
  },
  rate: {
    fontWeight: variables.fw_light,
  },
});

export default function SkillsModal() {
  const classes = useStyles();
  const [skills, setSkills] = React.useState(false);
  const width = window.innerWidth;

  const handleOpenSkills = () => {
    setSkills(true);
  };

  const handleCloseSkills = () => {
    setSkills(false);
  };

  const skillsDev = skillsData.dev;
  const skillsDesign = skillsData.design;
  const skillsOther = skillsData.other;
  const skillsFreeTime = skillsData.freeTime;

  return (
    <>
      <h3
        className={clsx(
          classes.expandMore,
          width < 768 && classes.expandMoreMobile
        )}
        onClick={handleOpenSkills}
      >
        Click for expand
      </h3>
      {skills ? (
        <>
          <div className={classes.skillsModal}>
            <div className={classes.closeBlock}>
              <Close onClick={handleCloseSkills} />
            </div>
            <div
              className={
                width > 768 ? classes.container : classes.containerSmall
              }
            >
              <h2>Coding</h2>
              <div
                className={clsx(
                  classes.skillBlock,
                  width < 768 && classes.skillBlockSmall
                )}
              >
                {skillsDev.front.map((dev, index) => (
                  <div key={index} className={classes.skillItem}>
                    <div>
                      <p>{dev.name}</p>
                      <p className={classes.rate}>{dev.rate}</p>
                    </div>
                  </div>
                ))}
                {skillsDev.back.map((dev, index) => (
                  <div key={index} className={classes.skillItem}>
                    <div>
                      <p>{dev.name}</p>
                      <p className={classes.rate}>{dev.rate}</p>
                    </div>
                  </div>
                ))}
                {skillsDev.framework.map((dev, index) => (
                  <div key={index} className={classes.skillItem}>
                    <div>
                      <p>{dev.name}</p>
                      <p className={classes.rate}>{dev.rate}</p>
                    </div>
                  </div>
                ))}
                {skillsDev.versionning.map((dev, index) => (
                  <div key={index} className={classes.skillItem}>
                    <div>
                      <p>{dev.name}</p>
                      <p className={classes.rate}>{dev.rate}</p>
                    </div>
                  </div>
                ))}
                {skillsDev.cms.map((dev, index) => (
                  <div key={index} className={classes.skillItem}>
                    <div>
                      <p>{dev.name}</p>
                      <p className={classes.rate}>{dev.rate}</p>
                    </div>
                  </div>
                ))}
              </div>
              <h2>Design</h2>
              <div className={classes.skillBlocks}>
                <div
                  className={clsx(
                    classes.skillBlock,
                    width < 768 && classes.skillBlockSmall
                  )}
                >
                  {skillsDesign.map((design, index) => (
                    <div key={index} className={classes.skillItem}>
                      <div>
                        <p>{design.name}</p>
                        <p className={classes.rate}>{design.rate}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <h2>Other</h2>
              <div
                className={clsx(
                  classes.skillBlock,
                  width < 768 && classes.skillBlockSmall
                )}
              >
                {skillsOther.map((other, index) => (
                  <div key={index} className={classes.skillItem}>
                    <div>
                      <p>{other.name}</p>
                    </div>
                  </div>
                ))}
              </div>
              <h2>Free time</h2>
              <div
                className={clsx(
                  classes.skillBlock,
                  width < 768 && classes.skillBlockSmall
                )}
              >
                {skillsFreeTime.map((free, index) => (
                  <div key={index} className={classes.skillItem}>
                    <div>
                      <p>{free.name}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

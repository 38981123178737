import React from "react";
import { createUseStyles } from "react-jss";
import clsx from "clsx";
import MenuHeader from "../components/Menu/MenuHeader";
import contact from "../data/contact.json";
import * as variables from "../variables";

const useStyles = createUseStyles({
  container: {
    overflow: "auto",
    height: "100vh",
  },
  blockContact: {
    marginTop: variables.space1,
    padding: "0 50px",
  },
  title: {
    textTransform: "uppercase",
    fontSize: variables.fs_xl,
    fontWeight: variables.fw_black,
    margin: 0,
  },
  text: {
    marginTop: variables.space2,
    fontSize: variables.fs_xs,
    lineHeight: "1.5em",
  },
  link: {
    fontWeight: variables.fw_bold,
    textTransform: "uppercase",
    fontFamily: "Montserrat",
    textDecoration: "none",
    "&:hover": {
      "-webkit-text-stroke": "0.8px #38383a",
      color: "transparent",
    },
  },
});

export default function Contact() {
  const classes = useStyles();
  const width = window.innerWidth;

  return (
    <div className={classes.container}>
      <MenuHeader />
      <div className={classes.blockContact}>
        <h1 className={classes.title}>{contact.title}</h1>
        <p className={classes.text}>
          If you want talk with me or just sent me a joke, you can use my mail
          adress{" "}
          <span>
            <a className={classes.link} href="mailto:benjaminarpage@gmail.com">
              benjaminarpage@gmail.com
            </a>
          </span>{" "}
          or call me at{" "}
          <span>
            <a className={classes.link} href="tel:+33650228946">
              +33 6 50 22 89 46
            </a>
          </span>
          .
        </p>
        <p>
          Another link :{" "}
          <a
            className={classes.link}
            href="https://linkedin.com/in/benjamin-arpage"
            target="_blank"
          >
            LinkedIn
          </a>
          ,{" "}
          <a
            className={classes.link}
            href="https://github.com/moijesuisben"
            target="_blank"
          >
            GitHub
          </a>
          ,{" "}
          <a
            className={classes.link}
            href="https://twitter.com/Moijesuisben"
            target="_blank"
          >
            Twitter
          </a>
          .
        </p>
      </div>
    </div>
  );
}

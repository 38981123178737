import React from 'react';
import { createUseStyles } from 'react-jss';
import { layoutGenerator } from 'react-break';
import clsx from 'clsx';
import * as variables from '../../variables';
import OpenMenu from '../../menu.svg';
import Close from '../Close/Close';

const useStyles = createUseStyles({
  containerDesktop: {
    height: 60,
    padding: '0 20px',
    // backgroundColor: variables.akaroa,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  containerMobileHeader: {
    height: 60,
    padding: '0 20px',
    backgroundColor: variables.akaroa,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
  },
  containerMobileFullScreen: {
    zIndex: 2,
    height: '100vh',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    padding: '5% 0',
    backgroundColor: variables.akaroa,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  title: {
    fontWeight: variables.fw_bold,
    fontSize: variables.fs_sm,
    letterSpacing: '0.15vw',
    textTransform: 'uppercase',
    textDecoration: 'none',
    margin: 0,
    position: 'relative',
    '&:hover': {
      '-webkit-text-stroke': '1px #38383a',
      color: 'transparent',
    },
  },
  menuDesktop: {
    height: '100%',
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  menuMobilePosition: {
    height: '90%',
    display: 'flex',
    alignItems: 'center',
  },
  menuMobile: {
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    height: '50%',
  },
  underline: {
    position: 'relative',
    margin: '0 20px 0 20px',
    textTransform: 'uppercase',
    textDecoration: 'none',
    fontWeight: variables.fw_bold,
    color: variables.tuna,
  },
  underlineDesktop: {
    fontSize: variables.fs_xs,
    '&:after': {
      bottom: '-5px',
      height: '3px',
      content: 'close-quote',
      position: 'absolute',
      left: '0',
      backgroundColor: variables.tuna,
      width: 0,
      transition: 'width 0.4s',
    },
    '&:hover:after': {
      width: '100%',
    },
  },
  underlineMobile: {
    fontSize: variables.fs_lg,
  },
  closeMenuMobile: {
    position: 'absolute',
    top: '15%',
    height: variables.fs_xl,
  },
  openMenuMobile: {
    height: '30px',
  },
});

export default function MenuHeader() {
  const classes = useStyles();

  const layout = layoutGenerator({
    mobile: 0,
    desktop: 992,
  });

  const OnMobile = layout.is('mobile');
  const OnDesktop = layout.is('desktop');

  const [open, setOpen] = React.useState(false);

  const handleOpenMenu = () => {
    setOpen(true);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  return (
    <>
      <OnDesktop>
        <div className={classes.containerDesktop}>
          <a href="/" className={classes.title}>Benjamin Arpage</a>
          <ul className={classes.menuDesktop}>
            <li>
              <a href="/about" className={clsx(classes.underline, classes.underlineDesktop)}>
                about
              </a>
            </li>
            <li>
              <a href="/projects" className={clsx(classes.underline, classes.underlineDesktop)}>
                projects
              </a>
            </li>
            <li>
              <a href="/contact" className={clsx(classes.underline, classes.underlineDesktop)}>
                contact
              </a>
            </li>
          </ul>
        </div>
      </OnDesktop>
      <OnMobile>
        {open ? (
          <div className={classes.containerMobileFullScreen}>
            <Close onClick={handleCloseMenu} absolute />
            <div className={classes.menuMobilePosition}>
              <ul className={classes.menuMobile}>
              <li>
                  <a href="/" className={clsx(classes.underline, classes.underlineMobile)}>
                    home
                  </a>
                </li>
                <li>
                  <a href="/about" className={clsx(classes.underline, classes.underlineMobile)}>
                    about
                  </a>
                </li>
                <li>
                  <a href="/projects" className={clsx(classes.underline, classes.underlineMobile)}>
                    projects
                  </a>
                </li>
                <li>
                  <a href="/contact" className={clsx(classes.underline, classes.underlineMobile)}>
                    contact
                  </a>
                </li>
              </ul>
            </div>
            <a href="/" className={classes.title}>Benjamin Arpage</a>
          </div>
        ) : (
          <div className={classes.containerMobileHeader}>
            <img src={OpenMenu} alt="open menu" className={classes.openMenuMobile} onClick={handleOpenMenu} />
          </div>
        )}
      </OnMobile>
    </>
  );
}

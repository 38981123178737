import React from 'react';
import { createUseStyles } from 'react-jss';
import * as variables from '../../variables';

const useStyles = createUseStyles({
  logo: {
    height: 'calc(50px + (75 - 50) * ((100vw - 320px) / (1920 - 320)))',
    width: 'calc(50px + (75 - 50) * ((100vw - 320px) / (1920 - 320)))',
    fill: variables.tuna,
    position: 'absolute',
    display: 'flex',
    bottom: variables.space1,
  },
});

export default function Logo() {
  const classes = useStyles();

  return (
    <svg
      className={classes.logo}
      viewBox="0 0 439.09 439.32"
    >
      <title>logo</title>
      <path
        d="M197,30c5.51,2.14,11.49,3.53,16.45,6.59,11,6.8,14.87,17.82,15.49,30.25,1.18,23.72-2.83,46.87-8.63,69.65-6.64,26.1-14.14,52-21.84,77.8-2.71,9.06-1.31,15.88,5.45,22.57,7.74,7.67,16.89,12.27,27.1,15.18a220.39,220.39,0,0,1,69,33c37.73,27.15,41.85,65.4,26.4,102.27-7.76,18.52-20.7,33.26-35.11,46.84-7.27,6.85-14.66,13.57-22.07,20.28-7.85,7.12-7.89,7.08-1.2,14.88-8-.49-11.21-6.58-6.48-13.17,4.17-5.8,9.06-11.09,13.64-16.59,13.66-16.42,27-33.09,34.51-53.44a88.72,88.72,0,0,0,5.25-37.31c-1.22-17.82-10.94-30.6-24.32-41.28-15.39-12.28-33.09-20.4-50.8-28.52q-18.62-8.52-37.23-17c-20.83-9.55-29-26.67-23.85-49,6.74-29.16,13.93-58.25,19.49-87.65,2.88-15.25,3-31.08,3.67-46.68.19-4.28-1.4-8.89-3.11-13-2.28-5.43-7.19-7.5-12.34-4.69a52.08,52.08,0,0,0-14,10.8c-13.58,15.39-22.58,33.69-31.32,52C115.16,178.29,95.3,235.18,74,291.48,66.2,312,57.74,332.26,49.41,352.56c-1.28,3.11-3.57,5.79-5.38,8.67l-1.65-.11c-.8-2.38-2-4.71-2.32-7.15-1.78-15.37-4.34-30.74-4.81-46.16C33.64,254.88,32.06,201.93,31.91,149c-.09-29.76,2.53-59.52,4.23-89.27.31-5.59,1.76-11.17,3.24-16.61.52-1.9,3-4.87,4.07-4.67,2,.39,4.66,2.42,5.23,4.32,1.86,6.18,3.63,12.54,4.11,18.94,1.69,22.07,2.36,44.23,4.28,66.29,2,23.36,5.07,46.64,7.7,70A43.32,43.32,0,0,0,66.2,203c2.78-4.24,5.09-7.21,6.82-10.5,11.06-21.06,21.78-42.32,33-63.28,14.44-26.88,29.73-53.25,50.54-75.88A138.59,138.59,0,0,1,169.4,41.47,50.75,50.75,0,0,1,195,30Z"
        transform="translate(-31.91 -30)"
      />
      <path
        d="M471,431c-.43.85-.92,1.68-1.27,2.56-4,10-13.73,15.35-24.05,13.2a20.89,20.89,0,0,1-16.43-20.46c.11-10,7.15-18.59,16.6-20.28,10.72-1.92,20.09,3.3,23.94,13.32.35.91.81,1.77,1.21,2.66Z"
        transform="translate(-31.91 -30)"
      />
    </svg>
  );
}

import React from 'react';
import { createUseStyles } from 'react-jss';
import clsx from 'clsx';
import CloseMenu from '../../close.svg';
import CloseMenuHover from '../../closeHover.svg';
import * as variables from '../../variables';

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  closeProjectIcon: {
    height: variables.fs_xl,
  },
  absolute: {
    position: 'absolute',
    top: '15%',
  },
});

// eslint-disable-next-line react/prop-types
export default function Close({ onClick, absolute }) {
  const classes = useStyles();
  const [hover, setHover] = React.useState(false);

  const handleHoverClose = () => {
    setHover(true);
  };

  const handleOutClose = () => {
    setHover(false);
  };

  return (
    <div onMouseEnter={handleHoverClose} onMouseLeave={handleOutClose} onClick={onClick} className={classes.container}>
      {hover
        ? <img src={CloseMenuHover} alt="close project" className={clsx(classes.closeProjectIcon, absolute && classes.absolute)} />
        : <img src={CloseMenu} alt="close project" className={clsx(classes.closeProjectIcon, absolute && classes.absolute)} />}
    </div>
  );
}

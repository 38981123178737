import React from "react";
import { createUseStyles } from "react-jss";
import MenuHeader from "../components/Menu/MenuHeader";
import projects from "../data/projects.json";
import Project from "../components/Project/Project";

const useStyles = createUseStyles({
  projects_box: {
    display: "flex",
    flexDirection: "row",
    width: "200%",
    flexWrap: "wrap",
  },
  projects: {
    "& > *": {
      margin: "0 10px",
    },
  },
});

export default function Home() {
  const classes = useStyles();

  const width = window.innerWidth;
  console.log("width : ", width);

  const projectsTable = [];

  for (let index = 0; index < 50; index++) {
    projectsTable.push(
      projects.map((project) => (
        <div key={index} className={classes.projects}>
          <Project
            mediaCover={project.cover}
            name={project.name}
            year={project.year}
            description={project.description}
            dataMedia={project.medias}
            projectType={project.projectType}
            brief={project.brief}
            solution={project.solution}
            tools={project.tools}
          />
        </div>
      ))
    );
  }

  return (
    <>
      <MenuHeader />
      <div className={classes.projects_box}>{projectsTable}</div>
    </>
  );
}

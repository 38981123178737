import React from "react";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";
import * as variables from "../../variables";
import ProjectOpen from "./ProjectOpen";
import * as cover from "../../data/cover";

const styledBy = (property, mapping) => (props) => mapping[props[property]];

const useStyles = createUseStyles({
  project: {
    fontSize: variables.fs_xl,
    fontWeight: variables.fw_black,
    "-webkit-text-stroke": "2px #38383a",
    color: "transparent",
    textTransform: "uppercase",
    "&:hover": {
      backgroundImage: styledBy("mediaCover", {
        loki: cover.loki,
        fluff: cover.fluff,
        h36: cover.h36,
        sandraMartins: cover.sandraMartins,
        moyotia: cover.moyotia,
        mizu: cover.mizu,
        jo2024: cover.jo2024,
        rain: cover.rain,
        uniqlo: cover.uniqlo,
        pong: cover.pong,
        blackValley: cover.blackValley,
        nyne: cover.nyne,
        lodan: cover.lodan,
        coquelicot: cover.coquelicot,
        angersNantesOpera: cover.angersNantesOpera,
        jump: cover.jump,
        identiteVisuelle: cover.identiteVisuelle,
        dance: cover.dance,
      }),
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
      backgroundClip: "text",
      "-webkit-background-clip": "text",
      color: "transparent",
      "-webkit-text-stroke": "0px transparent",
    },
  },
  projectOpen: {
    overflow: "auto",
    zIndex: 2,
    position: "absolute",
    top: 0,
    left: 0,
    height: "100vh",
    width: "100vw",
    backgroundColor: variables.akaroa,
    padding: "5% 0",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  closeProjectIcon: {
    // position: 'absolute',
    top: "15%",
    height: variables.fs_xl,
  },
  name: {
    textAlign: "center",
    fontSize: variables.fs_xl,
    fontWeight: variables.fw_black,
    marginTop: variables.space2,
    marginBottom: variables.space1,
  },
  year: {
    margin: 0,
  },
  category: {},
  description: {
    paddingLeft: "calc(50px + (200 - 50) * ((100vw - 320px) / (1920 - 320)))",
    paddingRight: "calc(50px + (200 - 50) * ((100vw - 320px) / (1920 - 320)))",
    fontSize: variables.fs_xs,
  },
});

function Project({
  // eslint-disable-next-line react/prop-types
  name,
  year,
  category,
  projectType,
  brief,
  solution,
  tools,
  description,
  mediaCover,
  media,
  dataMedia,
}) {
  const classes = useStyles({ mediaCover });
  const [open, setOpen] = React.useState(false);

  const handleOpenProject = () => {
    setOpen(true);
  };

  const handleCloseProject = () => {
    setOpen(false);
  };

  return (
    <>
      <p className={classes.project} onClick={handleOpenProject}>
        {name}
      </p>
      {open ? (
        <ProjectOpen
          name={name}
          onClick={handleCloseProject}
          year={year}
          category={category}
          projectType={projectType}
          brief={brief}
          solution={solution}
          tools={tools}
          media={media}
          dataMedia={dataMedia}
        />
      ) : (
        <></>
      )}
    </>
  );
}

Project.propTypes = {
  mediaCover: PropTypes.oneOf([
    "loki",
    "fluff",
    "h36",
    "sandraMartins",
    "moyotia",
    "mizu",
    "jo2024",
    "rain",
    "uniqlo",
    "pong",
    "blackValley",
    "nyne",
    "lodan",
    "coquelicot",
    "angersNantesOpera",
    "jump",
    "identiteVisuelle",
    "dance",
  ]),
};

export default Project;

import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import PreLanding from "./views/PreLanding";
import Landing from "./views/Landing";
import Home from "./views/Home";
import About from "./views/About";
import Projects from "./views/Projects";
import Contact from "./views/Contact";

export default function BasicExample() {
  //let path = url.pathname;
  let path = new URL(window.location.href);
  console.log("path => ", path.pathname);
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/projects">
            <Projects />
          </Route>
          <Route path="/about">
            <About />
          </Route>
          <Route path="/contact">
            <Contact />
          </Route>
          {path != "/" || "/projects" || "/about" || "/contact" ? (
            <Redirect exact to="/" />
          ) : null}
        </Switch>
      </Router>
    </>
  );
}

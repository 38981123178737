import React from "react";
import { createUseStyles } from "react-jss";
import MenuHeader from "../components/Menu/MenuHeader";
import MenuProjects from "../components/Menu/MenuProjects";
import Project from "../components/Project/Project";
import projects from "../data/projects.json";
import { layoutGenerator } from "react-break";

const useStyles = createUseStyles({
  projects: {
    "& > *": {
      margin: "0.5em",
    },
  },
  blockProjectsDesktop: {
    padding: "0 50px",
    overflow: "auto",
    height: "60vh",
  },
  blockProjectsMobile: {
    padding: "0 25px",
    overflowX: "hidden",
    height: "80vh",
  },
  mediaProject: {
    maxWidth: 820,
    width: "calc(300px + (820 - 300) * ((100vw - 320px) / (1920 - 320)))",
    padding: 10,
  },
});

export default function About() {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [category, setCategory] = React.useState("AD");

  const handleOpenMenu = () => {
    setOpen(true);
  };

  const handleCloseMenu = () => {
    setOpen(false);
  };

  const handleClickAD = () => {
    setCategory("AD");
    setOpen(false);
  };
  const handleClickCC = () => {
    setCategory("CC");
    setOpen(false);
  };
  const handleClickWD = () => {
    setCategory("WD");
    setOpen(false);
  };

  projects.map((data, index) => console.log("test", data.medias));

  const layout = layoutGenerator({
    mobile: 0,
    desktop: 992,
  });
  const OnMobile = layout.is("mobile");
  const OnDesktop = layout.is("desktop");

  return (
    <>
      <MenuHeader />
      <MenuProjects
        onClickAD={handleClickAD}
        onClickCC={handleClickCC}
        onClickWD={handleClickWD}
        handleCloseMenu={handleCloseMenu}
        handleOpenMenu={handleOpenMenu}
        open={open}
      />
      <OnDesktop>
        <div className={classes.blockProjectsDesktop}>
          {projects.map((data, index) => (
            <div key={index} className={classes.projects}>
              {data.category === category ? (
                <Project
                  mediaCover={data.cover}
                  name={data.name}
                  category={data.category}
                  year={data.year}
                  description={data.description}
                  dataMedia={data.medias}
                  projectType={data.projectType}
                  brief={data.brief}
                  solution={data.solution}
                  tools={data.tools}
                />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </OnDesktop>
      <OnMobile>
        <div className={classes.blockProjectsMobile}>
          {projects.map((data, index) => (
            <div key={index} className={classes.projects}>
              {data.category === category ? (
                <Project
                  mediaCover={data.cover}
                  name={data.name}
                  category={data.category}
                  year={data.year}
                  description={data.description}
                  dataMedia={data.medias}
                />
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      </OnMobile>
    </>
  );
}
